const $ = jQuery.noConflict();

$(function () {
    $(document).on('click','.menu-item-toggle', function () {
        toggleMenu();
    })

    $(document).on('click','#menu-open', function () {
        openMenu();
    });

    $(document).on('click', '#menu-close', function () {
        closeMenu();
    });

    $(document).on('click','.menu-item', function () {
        if (!$(this).hasClass("menu-item-toggle") && !$(this).hasClass("menu-item-searchbar")) {
            if ($('.sidebar').hasClass('sidebar-active')) {
                $('.sidebar').removeClass('sidebar-active')
            }
        }
    });

    $('.menu-item-searchicon').hide();
});

function closeMenu() {
        $('.sidebar').removeClass('sidebar-active')
}
function openMenu() {
    $('.sidebar').addClass('sidebar-active')
}
function toggleMenu() {
    if ($('.menu-item-text').is(':visible')) {
        $('.menu-item-text').hide();
        $('.menu-item-spacer').show();
        $('.sidebar').width("50px");
        $('.menu-item-searchicon').show();
        $('.menu-item-searchbar').hide();
        $(".menu-item-toggle-icon").html('<i class="fa fa-solid fa-angles-right"></i>');
        setTimeout(function () {
            $('.main-content').css({ width: 'calc(100% - 50px)' });;
            $('.main-content').css({ left: '50px' });
        }, 250);
    }
    else {
        $('.sidebar').width("250px");
        $('.main-content').css({ width: 'calc(100% - 250px)' });;
        $('.main-content').css({ left: '250px' });
        $('.menu-item-searchicon').hide();
        $('.menu-item-searchbar').show();
        $(".menu-item-toggle-icon").html('<i class="fa fa-solid fa-angles-left"></i>');
        $('.menu-item-spacer').hide();

        setTimeout(function () {
            $('.menu-item-text').show();
        }, 250);
    }
}
