
import React from "react";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";


export function AuthMenu() {
    const { instance, accounts } = useMsal();
    

    const handleLogout = () => {

        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const getActiveAccount = () => {
        const activeAccount = instance.getActiveAccount();
        const accounts = instance.getAllAccounts();
        if (activeAccount != null) return activeAccount;
        if (accounts.length > 0) {
            const [firstAccount] = accounts;
            instance.setActiveAccount(firstAccount);
            return firstAccount;
        }
    }

    var username = getActiveAccount().username;

    return (
        <div>
            <span className="text-small">{username}&nbsp;&nbsp;</span>
            <i className="fa-solid fa-right-from-bracket" onClick={handleLogout}></i>
        </div>
    )
}