import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import MenuItem from '@mui/material/MenuItem';
import { getApiUrl } from "./authConfig";
import { Box, Modal } from "@mui/material";
import Autocomplete  from '@mui/material/Autocomplete';


export type CreateAssetDialogData = {
    id?: string,
    description: string,
    assetLocation: string, 
    assetType: string,
    status: string, 
    se04Required: string,
    model: string,
    manufacturer: string,
    productCode: string,
    productFamily: string,
    serialNumber: string,
    commSerialNumber: string,
    defaultTemplate: string
}

const statuses = ["Not Commissioned", "In Progress", "Commissioned", "Decommissioned"];
const assetLocations = ["Warehouse", "Vendor", "Contractor", "Building"]; 
const assetTypes = ["Water", "Gas", "Electricity", "Thermal", "Steam", "Communication"];

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export type CreateAssetDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateAssetDialogData
    buildingId?: string
    productList?: Array<any>
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>
} 

export const CreateAssetDialog = observer((props: CreateAssetDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;
    let buildingId = props.buildingId == null?  "": props.buildingId;

    var productList = props.productList || []; 
    var productFamilies = props.productFamilies || [];
    var defaultTemplates = props.defaultTemplates || [];

    productFamilies = productFamilies.filter(it => {
        let productIdx = productList.findIndex(prd => prd.productFamily == it.id);
        if(productIdx  == -1) {
            return false;
        }
        return true; 
    })

    productList = productList.sort((a, b)=> a.productFamily.localeCompare(b.productFamily));

    
    var initialData = props.initialData || {
        description: "", 
        assetLocation: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamily: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        description: "", 
        assetLocation: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamily: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }))

    const setInitialData = ()=> {
        formData.description =  initialData.description;
        formData.assetLocation =  initialData.assetLocation;
        formData.assetType =  initialData.assetType;
        formData.status =  initialData.status;
        formData.se04Required =  initialData.se04Required; 
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamily = initialData.productFamily;
        formData.serialNumber = initialData.serialNumber;
        formData.commSerialNumber = initialData.commSerialNumber;
        formData.defaultTemplate = initialData.defaultTemplate;

        if(initialData.productCode == "" && productList.length != 0 ) {
            let prd = productList[0]; 
            formData.assetType =  prd.assetType;
            formData.se04Required =  prd.se04Required; 
            formData.model =  prd.model;
            formData.manufacturer =  prd.manufacturer;
            formData.productCode = prd.productCode;
            formData.productFamily = prd.productFamily;
            formData.defaultTemplate = prd.defaultTemplate;
        }
    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        description: "",
        model: "",
        manufacturer: "", 
        serialNumber: "",
        commSerialNumber: ""
        
    }))

    const clearErrors = ()=> {
        formError.description = ""; 
        formError.model = ""; 
        formError.manufacturer = ""; 
        formError.serialNumber = "";
        formError.commSerialNumber= "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.description == "") {
            hasError = true; 
            formError.description = "Asset description is required";  
        }

        if(formData.model == "") {
            hasError = true; 
            formError.model = "Asset model is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Asset manufacturer is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("assets"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data, buildingId: buildingId})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const handleSubmitPlus = async () => {

        let hasError = false;
        clearErrors();

        if (formData.description == "") {
            hasError = true;
            formError.description = "Asset description is required";
        }

        if (formData.model == "") {
            hasError = true;
            formError.model = "Asset model is required";
        }

        if (formData.manufacturer == "") {
            hasError = true;
            formError.manufacturer = "Asset manufacturer is required";
        }

        if (hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == "" ? "POST" : "PUT";
        let url = getApiUrl("assets");

        if (existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, { ...data, buildingId: buildingId })

        if (fetchResponse.status != 409) {
            formData.serialNumber = "";
            setModalSuccessOpen(true)
        } else {
            setModalOpen(true)
        }

    } 

    const title = existingId == ""? "Create Asset": "Update Asset";

    const handleProductSelect = (productCode: string) => {
        formData.productCode = productCode;

        let selectedProduct = props.productList.find(it => it.productCode == productCode); 
        if(selectedProduct != null) {
            formData.description =  selectedProduct.description;
            formData.assetType =  selectedProduct.assetType;
            formData.se04Required =  selectedProduct.se04Required; 
            formData.model =  selectedProduct.model;
            formData.manufacturer =  selectedProduct.manufacturer;
            formData.productCode = selectedProduct.productCode;
            formData.productFamily = selectedProduct.productFamily;
            formData.defaultTemplate = selectedProduct.defaultTemplate;
        }
    }

    const handleProductFamilySelect = (pfamily: string) => {
        formData.productFamily = pfamily;
        let prd = productList.find(it => it.productFamily == pfamily); 

        if(prd != null) {
            formData.productCode = prd.productCode;
        }
    }

    return (

        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            >

            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: '12px'}}>
                    Please enter the information about the asset
                </DialogContentText>

                <Autocomplete
                    options={productFamilies}
                    getOptionLabel={(option) => option.name}
                    value={productFamilies.find(it => it.id == formData.productFamily)}
                    onChange={(event: any, newValue) => {
                        if(newValue == null ) {
                            return;
                        }
                        handleProductFamilySelect(newValue.id);
                        
                    }}
                    renderInput={(params) => <TextField {...params} label="Product Family" type="text" margin="dense" fullWidth/>}
                />

                <Autocomplete
                    options={productList}
                    getOptionLabel={(option) => option.productCode}
                    groupBy={(option) => {
                        let prf = productFamilies.find(it => it.id == option.productFamily)
                        return prf.name;
                    }}
                    value={productList.find(it => it.productCode == formData.productCode)}
                    onChange={(event: any, newValue) => {
                        if(newValue == null ) {
                            return;
                        }
                        handleProductSelect(newValue.productCode);
                    }}
                    renderInput={(params) => <TextField {...params} label="Product Code" type="text" margin="dense" fullWidth/>}
                />


                <TextField label="Description" type="text" margin="dense" fullWidth autoFocus error={formError.description != ""} 
                    helperText={formError.description} value={formData.description} onChange={e => formData.description = e.target.value}/>

                <TextField label="Asset Location" type="text" margin="dense" fullWidth select
                    value={formData.assetLocation} onChange={e => formData.assetLocation = e.target.value}>
                    {assetLocations.map(location => (
                        <MenuItem key={location} value={location}>
                            {location}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Status" type="text" margin="dense" fullWidth select
                    value={formData.status} onChange={e => formData.status = e.target.value}>
                    {statuses.map(status => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Serial Number" type="text" margin="dense" fullWidth error={formError.serialNumber != ""}
                    helperText={formError.serialNumber}  value={formData.serialNumber} onChange={e => formData.serialNumber = e.target.value}/>

                <TextField label="Communications Serial Number" type="text" margin="dense" fullWidth error={formError.commSerialNumber != ""}
                    helperText={formError.commSerialNumber} value={formData.commSerialNumber} onChange={e => formData.commSerialNumber = e.target.value}/>

                <TextField label="SE04 Required" type="text" margin="dense" fullWidth select
                    value={formData.se04Required} onChange={e => formData.se04Required = e.target.value}>
                    {["True", "False"].map(required => (
                        <MenuItem key={required} value={required}>
                            {required}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Asset Type" type="text" margin="dense" fullWidth select disabled
                    value={formData.assetType} onChange={e => formData.assetType = e.target.value}>
                    {assetTypes.map(type => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Model" type="text" margin="dense" fullWidth error={formError.model != ""} disabled
                    helperText={formError.model} value={formData.model} onChange={e => formData.model = e.target.value}/>

                <TextField label="Manufacturer" type="text" margin="dense" fullWidth error={formError.manufacturer != ""} disabled
                    helperText={formError.manufacturer} value={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />

                <Autocomplete
                    options={defaultTemplates}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    value={defaultTemplates.find(it => it.name == formData.defaultTemplate)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        formData.defaultTemplate = newValue.name;
                    }}
                    renderInput={(params) => <TextField {...params} label="Default Template" type="text" margin="dense" fullWidth />}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
                <Button onClick={handleSubmitPlus} color="primary">
                    Submit +
                </Button>
            </DialogActions>

            <Modal open={modalSuccessOpen} onClose={()=>setModalSuccessOpen(false)}>
                <Box sx={{ ...modalStyle, width: 450 }}>
                    <h2 style={{marginTop: '0px'}}>Asset Submit</h2>
                    <p>
                        The Asset has been added, Click to close.
                    </p>
                </Box>
            </Modal>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box sx={{ ...modalStyle, width: 450 }}>
                    <h2 style={{ marginTop: '0px' }}>Error</h2>
                    <p>
                        with the given manufacturer, product code and serial number already exists.
                    </p>
                </Box>
            </Modal>
        </Dialog>
    );
});
