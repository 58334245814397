import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CreateBuildingDialog } from '../CreateBuildingDialog'
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { getApiUrl } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, } from "ag-grid-community";

const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
    align-items: center;
`

export function BuildingsPage() {
    const [ buildingData, setBuildingData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 
    const gridRef = useRef<AgGridReact>(null);

    const requestBuildingData = async () => {
        setBuildingData([])
        let fetchResponse = await authFetch('GET', getApiUrl("buildings"))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Building Data Error: ' + error);
            } finally {
                setBuildingData(responseData);
            }
        }
    }

    useEffect(()=> {requestBuildingData()}, []);

    const handleDialogOpen = () => {
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestBuildingData(); 
        }
        setDialogOpen(false);
    }

    const CustomButtonComponent = (props) => {
        return (
            <TableCell align="right" style={{ padding: '5px' }}>
                <Button size="small" variant="contained" endIcon={<ElectricMeterIcon />} onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/assets/${props.data.id}`)
                }}>
                    Assets
                </Button>
            </TableCell>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "Building Name", field: "name" },
        { headerName: "Street Address", field: "address" },
        { field: "city" },
        { field: "province" },
        { field: "status" },
        { field: "Actions", maxWidth: 125, cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);
 

    return (
        <LoadingTemplate isLoading={ buildingData.length == 0}>
            <Box>
                <Fab color="primary" aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(4),
                    right: (theme) => theme.spacing(4)
                }} onClick={handleDialogOpen}>
                    <AddIcon />
                </Fab>
                <TitleRow>
                    <div style={{ flexGrow: '1' }}>Buildings</div>
                </TitleRow>

                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    <Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>

                </div>

                <div className="ag-theme-quartz" style={{ height: 700 }}>
                    <AgGridReact ref={gridRef} rowData={buildingData} statusBar={statusBar} sideBar={sideBar} columnDefs={colDefs} rowStyle={{ cursor: "pointer" }} onRowClicked={(e) =>
                        navigate(`/building/${e.data.id}`)
                    } />
                </div>

                <CreateBuildingDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit}/>
            
            </Box>
        </LoadingTemplate>
       
    );
};

