import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { getApiUrl } from '../authConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { CreateAssetDialog } from '../CreateAssetDialog';
import { Button } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, } from "ag-grid-community";

const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
    align-items: center;
`

export function AssetsPage() {
    const [ loading, setLoading ] = useState(true); 
    const [ buildingData, setBuildingData ] = useState([]);
    const [ assetData, setAssetData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ productData, setProductData ] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const gridRef = useRef<AgGridReact>(null);

    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 
    const params = useParams(); 

    let buildingId = params.buildingId || "";

    if(buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestProductFamiliesData = async () => {
        setProductFamiliesData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products/families`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Product Families Data Error: ' + error);
            } finally {
                setProductFamiliesData(responseData);
            }
        }
    }


    const requestProductData = async () => {
        setProductData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request product Data Error: ' + error);
            } finally {
                setProductData(responseData);
            }
        }
    }

    const requestBuildingData = async () => {
        setBuildingData([])
        let fetchResponse = await authFetch('GET', getApiUrl("buildings/slim"))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Building Data Error: ' + error);
            } finally {
                setBuildingData(responseData);
                if(buildingId == "" && responseData.length != 0) {
                    buildingId = responseData[0].id;
                }
            }
        }
    }

    const requestAssetData = async () => {
        setAssetData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Asset Data Error: ' + error);
            } finally {
                setAssetData(responseData);
            }
        }
    }

    const requestData = async ()=> {
        setLoading(true);
        await requestAssetData();
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamiliesData();
        await requestProductData();
        await requestBuildingData(); 
        await requestAssetData();
        setLoading(false);
    }

    useEffect(() => { requestInitialData()}, [params.buildingId]);

    const handleDialogOpen = () => {
       setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const [colDefs, setColDefs] = useState([
        { field: "assetType", maxWidth: 150 },
        { field: "productCode", maxWidth: 175 },
        { field: "manufacturer", maxWidth: 125 },
        { field: "model", maxWidth: 150 },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 125 },
        { headerName: "Communications Serial Num", field: "commSerialNumber" },
        { headerName: "Se04 Required", field: "se04Required", maxWidth: 125 },
        { field: "status", maxWidth: 175 }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <Box>
                <Fab color="primary" aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(4),
                    right: (theme) => theme.spacing(4)
                }} onClick={handleDialogOpen}>
                    <AddIcon />
                </Fab>

                <TitleRow>
                    <div style={{flexGrow: '1'}}>Assets</div>
                    <Autocomplete
                        options={buildingData}
                        getOptionLabel={(option) => option.name}
                        value={buildingData.find(it => it.id == buildingId)}
                        onChange={(event: any, newValue) => {
                            if(newValue == null ) {
                                return;
                            }
                            if(newValue.id != null && newValue.id != "") {
                                navigate(`/assets/${newValue.id}`)
                            }
                            
                          }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Building" />}
                        />

                </TitleRow>

                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    <Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>

                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} sideBar={sideBar} rowStyle={{ cursor: "pointer" }} onRowClicked={(e) =>
                        navigate(`/asset/${e.data.id}`)
                    } />
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} 
                    buildingId={buildingId} productList={productData} 
                    productFamilies={productFamiliesData}/>
            
            </Box>
        </LoadingTemplate>
    );
};

