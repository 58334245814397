import React, {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthFetch }  from './useAuthFetch'
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import MenuItem from '@mui/material/MenuItem';
import { minimalTimezoneSet } from 'compact-timezone-list';
import { getApiUrl } from "./authConfig";


export type CreateBuildingDialogData = {
    id: string,
    name: string, 
    address: string, 
    city: string, 
    province: string, 
    country: string, 
    postalCode: string, 
    latitude: number,
    longitude: number, 
    status: string, 
    timeZone: string
}


const statuses = ["Active", "Commissioning", "Predeployment", "Preinstallation", "Inactive", "Demo"];

export type CreateBuildingDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateBuildingDialogData
} 

export const CreateBuildingDialog = observer((props: CreateBuildingDialogProps)=> {
    const authFetch = useAuthFetch(); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    var initialData = props.initialData || {
        name: "", 
        address: "", 
        city: "", 
        province: "", 
        country: "", 
        postalCode: "", 
        latitude: 0,
        longitude: 0, 
        status: "Inactive", 
        timeZone: "Europe/London"
    }

    const formData = useLocalObservable(()=> ({
        name: initialData.name, 
        address: initialData.address, 
        city: initialData.city, 
        province: initialData.province, 
        country: initialData.country, 
        postalCode: initialData.postalCode, 
        latitude: initialData.latitude,
        longitude: initialData.longitude, 
        status: initialData.status, 
        timeZone:initialData.timeZone
        
    }))

    const formError = useLocalObservable(()=> ({
        name: "", 
        address: "", 
        city: "", 
        province: "", 
        country: "", 
        postalCode: ""
        
    }))

    const clearErrors = ()=> {
        formError.name = ""; 
        formError.address = ""; 
        formError.city = ""; 
        formError.province = ""; 
        formError.country = ""; 
        formError.postalCode = ""; 
    }


    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.name == "") {
            hasError = true; 
            formError.name = "Building name is required";  
        }

        if(formData.address == "") {
            hasError = true; 
            formError.address = "Building address is required";  
        }

        if(formData.city == "") {
            hasError = true; 
            formError.city = "Building city is required";  
        }

        if(formData.province == "") {
            hasError = true; 
            formError.province = "Building province is required";  
        }

        if(formData.country == "") {
            hasError = true; 
            formError.country = "Building country is required";  
        }

        if(formData.postalCode == "") {
            hasError = true; 
            formError.postalCode = "Building postalCode is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("buildings"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, data)
        if(props.onSubmit != null) {
            props.onSubmit("success")
        }
    } 

    const title = existingId == ""? "Create Building": "Update Building";

    return (
           
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: '12px'}}>
                    Please enter the information about the building
                </DialogContentText>
                <TextField label="Name" type="text" margin="dense" fullWidth autoFocus error={formError.name != ""} 
                    helperText={formError.name} value={formData.name} onChange={e => formData.name = e.target.value}/>
                <TextField label="Address" type="text" margin="dense" fullWidth error={formError.address != ""}
                    helperText={formError.address} value={formData.address} onChange={e => formData.address = e.target.value}/>
                <TextField label="City" type="text" margin="dense" fullWidth error={formError.city != ""}
                    helperText={formError.city} value={formData.city} onChange={e => formData.city = e.target.value}/>
                <TextField label="Province" type="text" margin="dense" fullWidth error={formError.province != ""}
                    helperText={formError.province} value={formData.province} onChange={e => formData.province = e.target.value}/>
                <TextField label="Country" type="text" margin="dense" fullWidth error={formError.country != ""}
                    helperText={formError.country} value={formData.country} onChange={e => formData.country = e.target.value}/>
                <TextField label="PostalCode" type="text" margin="dense" fullWidth error={formError.postalCode != ""}
                    helperText={formError.postalCode} value={formData.postalCode} onChange={e => formData.postalCode = e.target.value}/>
                <TextField label="Latitude" type="number" margin="dense" fullWidth
                    value={formData.latitude} onChange={e => formData.latitude = parseFloat(e.target.value)}/>
                <TextField label="Longitude" type="number" margin="dense" fullWidth 
                    value={formData.longitude} onChange={e => formData.longitude = parseFloat(e.target.value)}/>

                <TextField label="Status" type="text" margin="dense" fullWidth select
                    value={formData.status} onChange={e => formData.status = e.target.value}>
                    {statuses.map(status => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    ))}
                
                </TextField>
                
                <TextField label="Time Zone" type="text" margin="dense" fullWidth select
                    value={formData.timeZone} onChange={e => formData.timeZone = e.target.value}>
                    {minimalTimezoneSet.map(timeZone => (
                        <MenuItem key={timeZone.tzCode} value={timeZone.tzCode}>
                            {timeZone.label}
                        </MenuItem>
                    ))}
                </TextField>
                
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
})
