
import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

const redirectUri = process.env.NODE_ENV === 'production'? 
"https://mosaiq.qmeters.com/":
"http://localhost:3000"; 


export const msalConfig = {
    auth: {
        clientId: "d2048a3d-b188-482d-aa12-b2dda52455cd",
        authority: "https://login.microsoftonline.com/e94b0460-afb0-40c3-8259-363a4174ae12",
        redirectUri: redirectUri,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};


export const loginRequest = {
    scopes: ["api://21b7cf80-c271-4684-9437-188c5eafc187/SuperSoftWebApi.Global", "User.Read", "Directory.Read.All"]
};

export function getApiUrl(path: string): string {
    if (process.env.NODE_ENV === 'production') {
        return `https://api.mosaiq.qmeters.com/v1/${path}`
    }
    return `http://localhost:44351/v1/${path}`
}