import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuthFetch } from './useAuthFetch'
import { getApiUrl } from './authConfig';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { relative } from 'path/posix';


interface FileUploadProps {
    onComplete: () => void;
    itemId: string;
    itemType: string;
}

const FileUpload: React.FC<FileUploadProps> = ({ onComplete, itemId, itemType }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const authFetch = useAuthFetch();
    const { instance, accounts } = useMsal();
    const [progress, setProgress] = useState(0);

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        setProgress(0);

        var formData = new FormData();
        acceptedFiles.forEach((file) => {
           formData.append(file.name, file, file.name)
            console.log(file);
        });

        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        }).then((response) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = event => {
                setProgress(event.loaded / event.total * 100);
            };
            xhr.onreadystatechange = () => {
                if (xhr.readyState !== 4) return;
                if (xhr.status !== 201) {
                    console.log('error'); 
                }
                else if (xhr.status == 201) {
                }
                onComplete();
            };
            xhr.open('POST', getApiUrl(`files/${itemType}/${itemId}`), true);
            xhr.setRequestHeader('Authorization', `Bearer ${response.accessToken}`);
            xhr.send(formData);
        });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const styles: { [key: string]: React.CSSProperties } = {
        dropzone: {
            border: '2px dotted',
            borderColor: isDragActive || isHovered ? 'var(--color-primary)' : '#999',
            backgroundColor: isDragActive ? '#f5f5f5' : 'transparent',
            padding: '5px 10px',
            width: '100%',
            textAlign: 'center',
            cursor: 'pointer'
        }
    };

    return (
        <div
            {...getRootProps()}
            style={styles.dropzone }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >                <i className="fa-solid fa-file-circle-plus me-2" />
            Upload or Drop Files
            <div style={{ zIndex: 1, position: 'relative', width: `${progress}`, marginTop:'5px', backgroundColor: 'var(--color-secondary)', height: '5px', borderRadius:'5px' }}>
            </div>
        </div>
    );
};

export default FileUpload;
