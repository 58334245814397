﻿import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './assets/css/searchmodal.css'; // Optional CSS file
interface SearchModalProps {
    onClose: () => void;
    isOpen: boolean;
}

const SearchModal: React.FC<SearchModalProps> = ({ onClose, isOpen }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOpen) {
            inputRef.current.focus();
        }
    }, [isOpen]);

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
        else if (event.key === 'Enter') {
            if (searchQuery != '') {
                navigate(`search/${searchQuery}`)
                setSearchQuery('');
                onClose();
            }      
        }
    };

    if (!isOpen) return null;

    return (
        <div className="search-modal-overlay" onClick={onClose}>
            <div className="search-modal-content">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="form-control"
                />
            </div>
        </div>
    );
};

export default SearchModal;
