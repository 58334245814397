import React, { useEffect } from 'react'
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CreateBuildingDialog } from '../CreateBuildingDialog'
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { getApiUrl } from '../authConfig';
import { AuditLogPopup } from '../AuditLogPopup';
import { useNavigate, useParams } from 'react-router-dom';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import FileUpload from '../FileDrop'


const CardHeader = styled('div')`
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    color: #666;
`

const CardText = styled('div')`
    color: #666;
`

const TopRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    gap: 30px;
    @media screen and (max-width: 920px) {
        flex-direction: column;
    }
`
const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
`

const Row = styled(Box)`
    display: 'flex'; 
    flex-direction: 'row'; 
`
const faIconMap = {
    pdf: "fa-solid fa-file-pdf",
    doc: "fa-solid fa-file-word",
    docx: "fa-solid fa-file-word",
    xls: "fa-solid fa-file-excel",
    xlsx: "fa-solid fa-file-excel",
    csv: "fa-solid fa-file-csv",
    dwg: "fa-solid fa-compass-drafting",
    xml: "fa-solid fa-code",
    json: "fa-solid fa-code",
    jpg: "fa-solid fa-file-image",
    jpeg: "fa-solid fa-file-image",
    png: "fa-solid fa-file-image",
    bmp: "fa-solid fa-file-image",
    gif: "fa-solid fa-file-image",
    heif: "fa-solid fa-file-image"
}

const faFile = 'fa-solid fa-file';
export function BuildingDetailsPage() {
    const [ loading, setLoading ] = useState<boolean>(true); 
    const [ buildingData, setBuildingData ] = useState(null);
    const [ auditLogData, setAuditLogData ] = useState([]); 
    const [ noteText, setNoteText ] = useState("");
    const [ editDialogOpen, setEditDialogOpen ] = useState<boolean>(false);
    const [logPopupOpen, setLogPopupOpen] = useState<boolean>(false);
    const [fileData, setFileData] = useState([]);
    const [assetSummaryData, setAssetSummaryData] = useState(null);

    const navigate = useNavigate();
    const params = useParams(); 
    const authFetch = useAuthFetch(); 
    const buildingId = params.id;

    const requestBuildingData = async () => {
        setBuildingData(null)
        let fetchResponse = await authFetch('GET',  getApiUrl(`buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Building Data Error: ' + error);
            } finally {
                setBuildingData(responseData);
            }
        }
    }

    const requestNotesData = async () => {
        setNoteText("")
        let fetchResponse = await authFetch('GET', getApiUrl(`notes/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = {} as any;

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Notes Data Error: ' + error);
            } finally {
                setNoteText(responseData.noteText || "");
            }
        }
    }

    const requestFileData = async () => {
        setFileData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`files/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request File Data Error: ' + error);
            } finally {
                setFileData(responseData.reverse());
            }
        }
    }

    const requestAssetSummaryData = async () => {
        setAssetSummaryData(null)
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}/summary`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request File Data Error: ' + error);
            } finally {
                setAssetSummaryData(responseData);
            }
        }
    }


    const requestAuditLogData = async () => {
        setAuditLogData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`auditLog/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Audit Log Data Error: ' + error);
            } finally {
                setAuditLogData(responseData.reverse());
            }
        }
    }

    const requestData = ()=> {
        let tasks = [
            requestBuildingData(), 
            requestNotesData(),
            requestAuditLogData(),
            requestFileData(),
            requestAssetSummaryData()
        ];
        Promise.all(tasks).then(()=> setLoading(false));
    }

    const submitNotesData = async () => {
        let fetchResponse = await authFetch('POST', getApiUrl(`notes/buildings/${buildingId}`), {
            noteText: noteText
        })
        await requestAuditLogData()
    }
    
    const handleEditClick = async () =>  {
        setEditDialogOpen(true); 
    }

    const handleEditDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setEditDialogOpen(false);
    }

    const handleLogPopupOpen = () => {
        setLogPopupOpen(true); 
    }

    const handleLogPopupClose = () => {
        setLogPopupOpen(false); 
    }

    useEffect(requestData, []);

    const downloadFile = async (fileItem) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`files/${fileItem.id}/download`)).then(response => response.blob())
            .then(blob => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = fileItem.fileName; // Optional: Set a default file name
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl); // Cleanup
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    function getBuildingStatusBadge(status = "") {
      //  ["Active", "Commissioning", "Predeployment", "Preinstallation", "Inactive", "Demo"];
        switch (status.toLowerCase()) {
            case "predeployment":
                return <span className="badge bg-warning float-end">Pre-Deployment</span>
            case "commissioning":
                return <span className="badge bg-warning float-end">Commmissioning</span>
                break;
            case "preinstallation":
                return <span className="badge bg-warning float-end">Pre-Installation</span>
                break;
            case "inactive":
                return <span className="badge bg-danger float-end">Inactive</span>
                break;
            case "active":
                return <span className="badge bg-success float-end">Active</span>
                break;
            default:
                return <span className="badge bg-info float-end">{status}</span>
        }
    }


    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <h1>{buildingData?.name}</h1>
                <div className="row">
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            {/*TODO handle edit click*/}
                            <div className="card-title card-title-collapsable">
                                Building Info
                            </div>
                            <div className="card-body">
                                {getBuildingStatusBadge(buildingData?.status)}

                                <b>{buildingData?.name}</b><br />
                                {buildingData?.address}<br />
                                {buildingData?.city}, {buildingData?.province} <br />
                                {buildingData?.country}&nbsp;&nbsp;{buildingData?.postalCode}<br />
                                <br />
                                <b>Timezone: </b>{buildingData?.timeZone}
                                <br />
                                <span className="text-small">
                                    <b>Created: </b>{new Date(buildingData?.creationDate).toDateString()}  by {buildingData?.creatorEmail}
                                </span>
                                <button type="button" className="btn btn-sm btn-secondary float-end" onClick={handleEditClick}>edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Assets
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }} >
                                <a href={`/assets/${buildingId}`}>View All Assets</a><br /><br />
                                <h4>Asset Types</h4>
                                {assetSummaryData?.assetTypes.map((item) => {
                                    return (<>{item.type}: {item.quantity}<br /></>);
                                })
                                }
                                <br/>
                                S-E-04 Required: {assetSummaryData?.se04count}<br/><br/>
                                <span className="text-small">Last Asset Created: {new Date(assetSummaryData?.lastCreationDate).toLocaleString()} by {assetSummaryData?.lastCreatorName}</span>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col col-12 col-lg-6 col-xl-4">*/}
                    {/*    <div className="card">*/}
                    {/*        <div className="card-title card-title-collapsable">*/}
                    {/*            Site Inspections*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body scroll-y" style={{ maxHeight: "350px" }} >*/}
                    {/*            <span>No inspections performed</span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Files
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }}>
                                <FileUpload itemId={buildingId} itemType="buildings" onComplete={requestFileData} />
                                {fileData.length > 0 ? (
                                    fileData.map((fileItem, idx) => {
                                        //get the appropriate icon if no file preview is available
                                        const fileExtension = fileItem.fileName.split('.').pop().toLowerCase();
                                        const icon = faIconMap[fileExtension] || faFile;

                                        return (
                                            <div className="row mt-1 mb-2 p-2 clickable hoverable rounded" onClick={() => downloadFile(fileItem)} key={fileItem.id}>
                                                <div className="col-1 clickable" >
                                                    {fileItem.filePreview ?
                                                        <img src={fileItem.filePreview ? `data:${fileItem.mimeType};base64,${fileItem.filePreview}` : undefined}
                                                            style={{ maxHeight: "40px", maxWidth: "40px" }}
                                                        />
                                                        :
                                                        <span className="fa-stack">
                                                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                            <i className={`fas ${icon} fa-stack-1x fa-inverse`}></i>
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col ms-2">
                                                    {fileItem.fileName}<br />
                                                    <span className="text-small">{fileItem.creatorName
                                                        ? `${new Date(fileItem.creationDate).toLocaleString()} by ${fileItem.creatorName}`
                                                        : new Date(fileItem.creationDate).toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="text-center m-3">No files uploaded</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-title card-title-collapsable">
                            Notes
                        </div>
                        <div className="card-body">
                            <div className="col col-12">
                                <textarea style={{ width: "100%" }} placeholder="Notes" value={noteText} onChange={ev => setNoteText(ev.target.value)} />
                                <button type="button" className="btn btn-sm btn-primary float-end mt-2" onClick={submitNotesData}>save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-title card-title-collapsable">
                            Audit Log
                        </div>
                        <div className="card-body">
                            <div className="col col-12">
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Notes</TableCell>
                                                <TableCell align="right">User</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {auditLogData.slice(0, 10).map((log, idx) => (
                                                <TableRow
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {new Date(log.creationDate).toLocaleString()}
                                                    </TableCell>
                                                    <TableCell>{log.changeText}</TableCell>
                                                    <TableCell align="right">{log.creatorEmail}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <CreateBuildingDialog isOpen={editDialogOpen} onSubmit={handleEditDialogSubmit} initialData={{...buildingData}}/>
            <AuditLogPopup isOpen={logPopupOpen} onClose={handleLogPopupClose} initialData={[...auditLogData]}/>
        </LoadingTemplate>
       
    );
};

