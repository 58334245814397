import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestBuildingData (authFetch: any) {
    let fetchResponse = await authFetch('GET', getApiUrl("buildings/slim"))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request Building Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}