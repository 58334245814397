import React, { Children, ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

type LoadingTemplateProps = {
    isLoading: boolean
    fallback?: ReactNode
    children?: ReactNode
}

export function LoadingTemplate(props: LoadingTemplateProps) {

    if(props.isLoading) {

        if(props.fallback == null) {
            return (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress/>
                </Box>
            )
        }

        return props.fallback; 
    }

    return props.children; 
}
