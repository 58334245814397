import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from "react";
import Box from '@mui/material/Box';
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { CreateAssetDialog } from '../CreateAssetDialog';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef } from "ag-grid-community";

import { requestAssetData } from "../hooks/Asset";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestBuildingData } from "../hooks/Building";
import { requestTemplateData } from "../hooks/Template";

const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
    align-items: center;
`

class QABadgeCellRenderer {
    init(params) {
        this.eGui = document.createElement('span');
        this.eGui.className = 'badge'; // Bootstrap badge class

        // Set styles based on value or other conditions
        if (params.data[params.column.colId] == null || params.data[params.column.colId].passed == null) {
            this.eGui.classList.add('bg-secondary');
            this.eGui.innerText = 'Incomplete'
        } 

        else if (params.data[params.column.colId].passed ) {
            this.eGui.classList.add('bg-success');
            this.eGui.innerText = params.data[params.column.colId].date; // Set the badge text
        } 
        else if (!params.data[params.column.colId].passed) {
            this.eGui.classList.add('bg-danger');
            this.eGui.innerText = params.data[params.column.colId].date; // Set the badge text
        } 
    }

    getGui() {
        return this.eGui;
    }
}

export function TestPage() {
    const gridRef = useRef<AgGridReact>(null);
    const [loading, setLoading] = useState(true);
    const [buildingData, setBuildingData] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productData, setProductData] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState([]);

    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();

    let buildingId = params.buildingId || "";

    if (buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestData = async () => {
        setLoading(true);
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {
            setAssetData(data);

        });
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch).then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
            }
        });
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {

            // manually add some fields to asset data as a test:
            data[0].qaReview = {
                passed: true,
                date: '2024-09-01',
                user: 'marcela@qmeters.com'
            };
            data[1].qaReview = {
                passed: false,
                date: '2024-09-01',
                user: 'marcela@qmeters.com'
            };
            data[2].qaReview = {
                passed: null,

            };
            data[3].qaReview = null;

            data[0].qaApproval = {
                passed: true,
                date: '2024-09-02',
                user: 'terry@qmeters.com'
            };

            setAssetData(data);
        });

        setLoading(false);
    }

    useEffect(() => { requestInitialData() }, [params.buildingId]);

    const handleDialogOpen = () => { setDialogOpen(true); }

    const handleDialogSubmit = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setDialogOpen(false);
    }

    const [colDefs, setColDefs] = useState([
        { field: "assetType", enableRowGroup: true, maxWidth: 150 },
        { field: "productCode", enableRowGroup: true, maxWidth: 175 },
        { field: "manufacturer", enableRowGroup: true, maxWidth: 125 },
        { field: "model", enableRowGroup: true, maxWidth: 150 },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 125 },
        {
            headerName: "QA Review",
            field: "qaReview",
            valueGetter: (params) => qaValueGetter(params.data.qaReview),
            //cellStyle: (params) => { return qaFieldStyle(params.data) }
            cellRenderer: QABadgeCellRenderer
        },
        {
            headerName: "QA Approval",
            field: "qaApproval",
            valueGetter: (params) => qaValueGetter(params.data.qaApproval),
            //cellStyle: (params) => { return qaFieldStyle(params.data) }
            cellRenderer: QABadgeCellRenderer
        },
        { headerName: "Communications Serial Num", field: "commSerialNumber" },
        { headerName: "S-E-04 Required", field: "se04Required", maxWidth: 125 },
        { headerName: "Status", field: "status", maxWidth: 175 }
       
    ]);

    function qaValueGetter(data) {
        if (data == null || data.passed == null) {
            return "Incomplete"
        }

        if (data.passed) {
            return "Passed"
        }

        return "Failed";
    }

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <Box>
                <Fab color="primary" aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(4),
                    right: (theme) => theme.spacing(4)
                }} onClick={handleDialogOpen}>
                    <AddIcon />
                </Fab>

                <TitleRow>
                    <div style={{ flexGrow: '1' }}>This is a Dev Testing Page</div>
                    <Autocomplete
                        options={buildingData}
                        getOptionLabel={(option) => option.name}
                        value={buildingData.find(it => it.id == buildingId)}
                        onChange={(event: any, newValue) => {
                            if (newValue == null) {
                                return;
                            }
                            if (newValue.id != null && newValue.id != "") {
                                navigate(`/assets/${newValue.id}`)
                            }
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Building" />}
                    />
                </TitleRow>

                <div className="row mb-2">
                    <div className="col col-8 col-lg-3 col-sm-6">
                        <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    </div>
                    <div className="col">
                        <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                        <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                    </div>
                    {/*<Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>*/}
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} sideBar={sideBar} rowStyle={{ cursor: "pointer" }} rowGroupPanelShow={"always"} onRowClicked={(e) =>
                        navigate(`/asset/${e.data.id}`)
                    } />
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit}
                    buildingId={buildingId} productList={productData}
                    productFamilies={productFamiliesData}
                />

            </Box>
        </LoadingTemplate>
    );
};