import React, { useEffect } from 'react'
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CreateBuildingDialog } from './CreateBuildingDialog'
import { useAuthFetch } from './useAuthFetch'
import { LoadingTemplate } from './LoadingTemplate';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles"
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { getApiUrl } from './authConfig';
import { AuditLogPopup } from './AuditLogPopup';
import { useNavigate, useParams } from 'react-router-dom';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

const CardHeader = styled('div')`
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    color: #666;
`

const CardText = styled('div')`
    color: #666;
`

const TopRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    gap: 30px;
    @media screen and (max-width: 920px) {
        flex-direction: column;
    }
`
const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
`

const Row = styled(Box)`
    display: 'flex'; 
    flex-direction: 'row'; 
    
`

export function FilesPage() {
    const [ loading, setLoading ] = useState(true); 
    const [ buildingData, setBuildingData ] = useState(null);
    const [ auditLogData, setAuditLogData ] = useState([]); 
    const [ noteText, setNoteText ] = useState("");
    const [ editDialogOpen, setEditDialogOpen ] = useState(false);
    const [ logPopupOpen, setLogPopupOpen ] = useState(false);
    const navigate = useNavigate();
    const params = useParams(); 
    const authFetch = useAuthFetch(); 
    const buildingId = params.id;

    const requestBuildingData = async () => {
        setBuildingData(null)
        let fetchResponse = await authFetch('GET',  getApiUrl(`buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Building Data Error: ' + error);
            } finally {
                setBuildingData(responseData);
            }
        }
    }

    const requestNotesData = async () => {
        setNoteText("")
        let fetchResponse = await authFetch('GET', getApiUrl(`notes/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = {} as any;

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Notes Data Error: ' + error);
            } finally {
                setNoteText(responseData.noteText || "");
            }
        }
    }

    const requestAuditLogData = async () => {
        setAuditLogData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`auditLog/buildings/${buildingId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Audit Log Data Error: ' + error);
            } finally {
                setAuditLogData(responseData.reverse());
            }
        }
    }

    const requestData = ()=> {
        let tasks = [
            requestBuildingData(), 
            requestNotesData(),
            requestAuditLogData()
        ];
        Promise.all(tasks).then(()=> setLoading(false));
    }

    const submitNotesData = async () => {
        let fetchResponse = await authFetch('POST', getApiUrl(`notes/buildings/${buildingId}`), {
            noteText: noteText
        })
        await requestAuditLogData()
    }
    
    const handleEditClick = async () =>  {
        setEditDialogOpen(true); 
    }

    const handleEditDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setEditDialogOpen(false);
    }

    const handleLogPopupOpen = () => {
        setLogPopupOpen(true); 
    }

    const handleLogPopupClose = () => {
        setLogPopupOpen(false); 
    }

    useEffect(requestData, []);


    

    return (
        <LoadingTemplate isLoading={ loading }>
            <TitleRow>
                <div>{buildingData?.name}</div>
                <div style={{flexGrow: 1}}/>
                <Button variant="contained" endIcon={<ElectricMeterIcon />} onClick={()=> navigate(`/assets/${buildingId}`)}>
                    Assets
                </Button>
            </TitleRow>
            <TopRow>
                <Paper sx={{flexBasis: '50%', minHeight: '240px'}}>
                    <CardHeader sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{flexGrow: 1}}>Project Info</span>

                        <IconButton onClick={handleEditClick} sx={{padding: 0}}>
                            <EditIcon />
                        </IconButton>
                        
                    </CardHeader>
                    <Box sx={{display: 'flex', flexDirection: 'row', padding: '10px 20px 10px 20px'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', flexBasis: '50%'}}>
                            <CardText sx={{fontWeight: 'bold'}}>{buildingData?.name}</CardText>
                            <CardText>{buildingData?.address}</CardText>
                            <CardText>{buildingData?.city}, {buildingData?.province}</CardText>
                            <CardText>{buildingData?.country}  {buildingData?.postalCode}</CardText>

                            <CardText style={{marginTop: '12px'}}>
                                <span style={{fontWeight: 'bold'}}>Status:</span> 
                                <span> {buildingData?.status}</span>
                            </CardText>
                            <CardText style={{marginTop: '4px'}}>
                                <span style={{fontWeight: 'bold'}}>Timezone:</span> 
                                <span> {buildingData?.timeZone}</span>
                            </CardText>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', paddingLeft: '12px'}}>
                            <CardText>
                                <span style={{fontWeight: 'bold'}}>Created On:</span> 
                                <span> {new Date(buildingData?.creationDate).toDateString()}</span>
                            </CardText>
                            <CardText style={{marginTop: '4px'}}>
                                <span style={{fontWeight: 'bold'}}>Created By:</span> 
                                <span> {buildingData?.creatorEmail}</span>
                            </CardText>
                        </Box>
                    </Box>
                    
                </Paper>
                <Paper sx={{flexBasis: '50%',  minHeight: '240px'}}>
                    <CardHeader>
                        Communications
                    </CardHeader>
                </Paper>

            </TopRow>

            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '18px'}}>
                <Paper sx={{flexBasis: '100%'}}>
                    <CardHeader>
                        Notes
                    </CardHeader>
                    <Box sx={{display: 'flex', flexDirection: 'row', padding: '12px'}}>
                        <TextField label="Notes" multiline minRows={3} maxRows={3} sx={{flexBasis: '100%', marginTop: '8px'}}
                        value={noteText} onChange={ev => setNoteText(ev.target.value)}/>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row', padding: '12px', paddingTop: '4px'}}>
                        <Box sx={{flexGrow: 1}}/>
                        <Button onClick={submitNotesData} color="primary">
                            Submit
                        </Button>
                    </Box>
                   
                </Paper>
            </Box>


            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '18px'}}>
                <Paper sx={{flexBasis: '100%'}}>
                    <CardHeader sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span style={{flexGrow: 1}}>Activity Log</span>

                        <IconButton onClick={handleLogPopupOpen} sx={{padding: 0}}>
                            <OpenInFullIcon />
                        </IconButton>
                    </CardHeader>

                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell align="right">User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auditLogData.slice(0, 3).map((log, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {new Date(log.creationDate).toDateString()}
                                </TableCell>
                                <TableCell>{log.changeText}</TableCell>
                                <TableCell  align="right">{log.creatorEmail}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                   
                </Paper>
            </Box>

            <CreateBuildingDialog isOpen={editDialogOpen} onSubmit={handleEditDialogSubmit} initialData={{...buildingData}}/>
            <AuditLogPopup isOpen={logPopupOpen} onClose={handleLogPopupClose} initialData={[...auditLogData]}/>
        </LoadingTemplate>
       
    );
};

