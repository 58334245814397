import React, { useEffect } from 'react'
import { useState } from "react";
import { useAuthFetch } from './useAuthFetch'
import { LoadingTemplate } from './LoadingTemplate';
import { getApiUrl } from './authConfig';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

export function SearchPage() {
    const params = useParams();
    const location = useLocation();

    const authFetch = useAuthFetch();
    var searchQuery = params.query;
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        searchQuery = params.query;
        requestData();
    }, [location.pathname])

    const requestSearchData = async () => {
        setSearchData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`search?query=${searchQuery}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request search error: ' + error);
            } finally {
                setSearchData(responseData);
            }
        }
    }

    const requestData = () => {
        let tasks = [ requestSearchData() ];
        Promise.all(tasks).then(() => setLoading(false));
    }

    var lastType = "";
    return (
        <LoadingTemplate isLoading={loading}>
            <h1>Search Results: "{searchQuery}"</h1>
            {searchData.length == 0 ? "No results found" : ""}
            {searchData.map((searchResult) => {
                const displayItemType = lastType !== searchResult.itemType;
                lastType = searchResult.itemType; 

                return (
                    <>
                    { displayItemType && <h1>{lastType[0].toUpperCase() + lastType.slice(1)}</h1>}
                    <div className="mb-2 p-2 ps-4 hoverable rounded" key={searchResult.itemId }>
                        <b>Name: </b>
                        <a href={"/" + searchResult.url}>{searchResult.description}</a>
                        {searchResult.building && (
                            <>
                                <br/>
                                <b>Building: </b>
                                <a href={"/building/" + searchResult.building.id}>
                                    {searchResult.building.name}
                                </a>
                            </>
                            )}
                        </div>
                    </>
                );
            })}

        </LoadingTemplate>
    );
};

