import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { getApiUrl } from '../authConfig';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { CreateProductDialog } from '../CreateProductDialog';
import EditIcon from '@mui/icons-material/Edit';
import { AgGridReact } from 'ag-grid-react';
import { Button } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Link } from 'react-router-dom'

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef,} from "ag-grid-community";

const TitleRow = styled(Box)`
    display: flex; 
    flex-direction: row;
    margin-top: 8px; 
    margin-bottom: 18px; 
    margin-left: 8px;
    font-size: 20px; 
    font-weight: bold;
    color: #666;
`

export function ProductsPage() {
    const [ loading, setLoading ] = useState(true); 
    const [ productData, setProductData ] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const [templateItemsData, setTemplateItemsData] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogInitialData, setDialogInitialData ] = useState(null);
    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 
    const params = useParams(); 
    const gridRef = useRef<AgGridReact>(null);


    const requestProductFamiliesData = async () => {
        setProductFamiliesData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products/families`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Product Families Data Error: ' + error);
            } finally {
                setProductFamiliesData(responseData);
            }
        }
    }

    const requestTemplateItemsData = async () => {
        setTemplateItemsData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`templates`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Template Items Data Error: ' + error);
            } finally {
                setTemplateItemsData(responseData);
            }
        }
    }

    const requestProductData = async () => {
        setProductData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Product Data Error: ' + error);
            } finally {
                setProductData(responseData);
            }
        }
    }

    const requestData = async ()=> {
        await requestProductFamiliesData();
        requestTemplateItemsData();
        await requestProductData();
        setLoading(false);
    }

    useEffect(()=> {requestData()}, []);


    const handleDialogOpen = (initData = null) => {
        setDialogInitialData(initData);
        setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const CustomButtonComponent = (props) => {
        if (props !=null && props.data !=null) {
            return (
                <TableCell align="right" style={{ padding: '5px' }}>
                    <Button size="small" variant="contained" endIcon={<EditIcon />} onClick={(ev) => {
                        ev.stopPropagation();
                        handleDialogOpen({ ...props.data });
                    }}>
                        Edit
                    </Button>

                </TableCell>
            )
        }

    };

    const [colDefs, setColDefs] = useState([
        { field: "assetType", maxWidth: 150 },
        { field: "productCode", maxWidth: 150 },
        { field: "manufacturer" },
        { field: "model" },
        { headerName: "Se04 Required", field: "se04Required", maxWidth: 150 },
        { field: "defaultTemplate" },
        { field: "Actions", cellRenderer: CustomButtonComponent }
    ]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <Box>
                <Fab color="primary" aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(4),
                    right: (theme) => theme.spacing(4)
                }} onClick={()=>handleDialogOpen()}>
                    <AddIcon />
                </Fab>

                <TitleRow>
                    <div>Products</div>
                </TitleRow>
                <Link to={"../ProductFamily"}>ProductFamily </Link>
                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    <Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>

                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={productData} columnDefs={colDefs} sideBar={sideBar} statusBar={statusBar} />
                </div>

                <CreateProductDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} initialData={dialogInitialData} productFamilies={productFamiliesData} defaultTemplates={templateItemsData} />
            
            </Box>
        </LoadingTemplate>
       
    );
};

