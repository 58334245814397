import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuthFetch } from './useAuthFetch'
import { getApiUrl } from './authConfig';
import { useNavigate, useLocation } from 'react-router-dom';


interface NavBarItemProps {
    text: string;
    path: string;
    icon: string;
}

const FileUpload: React.FC<NavBarItemProps> = ({ text, path, icon }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavClick = (item) => {
        navigate(item);
    }

    function IsActive(itemPath) {
        const path = location.pathname.toLowerCase();
        return path.startsWith(itemPath.toLowerCase().replace(/s$/, '')) ? "active" : "";
    }

    return (
        <div className={`menu-item ${IsActive(path)}`} onClick={() => handleNavClick(path)}>
            <div className="menu-item-icon">
                <i className={icon}></i>
            </div>
            <div className="menu-item-text">
                {text}
            </div>
        </div>
    );
};

export default FileUpload;
