import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import MenuItem from '@mui/material/MenuItem';
import { getApiUrl } from "./authConfig";
import { Box, Modal } from "@mui/material";
import Autocomplete  from '@mui/material/Autocomplete';

export type CreateProductDialogData = {
    id?: string,
    description: string,
    assetType: string,
    se04Required: string,
    model: string,
    manufacturer: string,
    productCode: string,
    productFamily: string,
    defaultTemplate: string
}

const assetTypes = ["Water", "Gas", "Electricity", "Thermal", "Steam", "Communication"];

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export type CreateProductDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateProductDialogData
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>;
} 

export const CreateProductDialog = observer((props: CreateProductDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [ modalOpen, setModalOpen ] = useState(false); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    const productFamilies = props.productFamilies || [];
    const defaultTemplates = props.defaultTemplates || [];

    const initialData = props.initialData || {
        description: "", 
        assetType: "Water", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        description: "", 
        assetType: "Water", 
        se04Required: "False", 
        model: "", 
        manufacturer: "",
        productCode: "", 
        productFamily: "",
        defaultTemplate: ""
    }))


    const setInitialData = ()=> {
        formData.description =  initialData.description;
        formData.assetType =  initialData.assetType;
        formData.se04Required =  initialData.se04Required; 
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamily = initialData.productFamily;
        formData.defaultTemplate = initialData.defaultTemplate;

        if(initialData.productFamily == "" && productFamilies.length != 0 ) {
            let fam = productFamilies[0]; 
            formData.productFamily = fam.id; 
            formData.assetType = fam.category;
        }

    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        description: "",
        model: "",
        manufacturer: "", 
        productCode: "", 
        serialNumber: "",
        commSerialNumber: ""
    }))

    const clearErrors = ()=> {
        formError.description = ""; 
        formError.model = ""; 
        formError.manufacturer = ""; 
        formError.productCode = "";
        formError.serialNumber = "";
        formError.commSerialNumber = "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.description == "") {
            hasError = true; 
            formError.description = "Product description is required";  
        }

        if(formData.model == "") {
            hasError = true; 
            formError.model = "Product model is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Product manufacturer is required";  
        }

        if(formData.productCode == "") {
            hasError = true; 
            formError.productCode = "Product code is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("products"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Product": "Update Product";

    return (

        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: '12px'}}>
                    Please enter the information about the product
                </DialogContentText>
                <TextField label="Description" type="text" margin="dense" fullWidth autoFocus error={formError.description != ""} 
                    helperText={formError.description} value={formData.description} onChange={e => formData.description = e.target.value}/>


                <Autocomplete
                    options={productFamilies}
                    getOptionLabel={(option) => option.name}
                    value={productFamilies.find(it => it.id == formData.productFamily)}
                    onChange={(event: any, newValue) => {
                        if(newValue == null ) {
                            return;
                        }

                        formData.productFamily = newValue.id;
                        formData.assetType = newValue.category;
                        
                    }}
                    renderInput={(params) => <TextField {...params} label="Product Family" type="text" margin="dense" fullWidth/>}
                />


                <TextField label="Asset Type" type="text" margin="dense" fullWidth select disabled
                    value={formData.assetType} onChange={e => formData.assetType = e.target.value}>
                    {assetTypes.map(type => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="SE04 Required" type="text" margin="dense" fullWidth select
                    value={formData.se04Required} onChange={e => formData.se04Required = e.target.value}>
                    {["True", "False"].map(required => (
                        <MenuItem key={required} value={required}>
                            {required}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Model" type="text" margin="dense" fullWidth error={formError.model != ""}
                    helperText={formError.model} value={formData.model} onChange={e => formData.model = e.target.value}/>

                <TextField label="Manufacturer" type="text" margin="dense" fullWidth error={formError.manufacturer != ""}
                    helperText={formError.manufacturer} value={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value}/>

                <TextField label="Product Code" type="text" margin="dense" fullWidth  error={formError.productCode != ""}
                    helperText={formError.productCode} value={formData.productCode} onChange={e => formData.productCode = e.target.value} />

                <Autocomplete
                    options={defaultTemplates}
                    getOptionLabel={(option) => option.name}
                    value={defaultTemplates.find(it => it.name == formData.defaultTemplate)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        formData.defaultTemplate = newValue.name;
                    }}
                    renderInput={(params) => <TextField {...params} label="Default Template" type="text" margin="dense" fullWidth />}
                />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>

            <Modal open={modalOpen} onClose={()=>setModalOpen(false)}>
                <Box sx={{ ...modalStyle, width: 450 }}>
                    <h2 style={{marginTop: '0px'}}>Error</h2>
                    <p>
                        The machine with the given manufacturer, product code and serial number already exists.
                    </p>
                </Box>
            </Modal>
        </Dialog>
    );
});
