import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import MenuItem from '@mui/material/MenuItem';
import { getApiUrl } from "./authConfig";
import { Box, Modal } from "@mui/material";
import Autocomplete  from '@mui/material/Autocomplete';

export type CreateProductFamilyDialogData = {
    id?: string,
    name: string,
    category: string,
    manufacturer: string,
    defaultTemplate: string
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export type CreateProductDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateProductFamilyDialogData
    defaultTemplates?: Array<any>;
} 

export const CreateProductFamilyDialog = observer((props: CreateProductDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [ modalOpen, setModalOpen ] = useState(false); 

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;

    const defaultTemplates = props.defaultTemplates || [];

    const initialData = props.initialData || {
        name: "", 
        category: "", 
        manufacturer: "", 
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({
        name: "",
        category: "",
        manufacturer: "",
        defaultTemplate: ""
    }))


    const setInitialData = ()=> {
        formData.name =  initialData.name;
        formData.category = initialData.category;
        formData.manufacturer = initialData.manufacturer; 
        formData.defaultTemplate = initialData.defaultTemplate;
    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        name: "",
        category: "",
        manufacturer: "",
        defaulttemplate: ""
    }))

    const clearErrors = ()=> {
        formError.name = ""; 
        formError.category = ""; 
        formError.manufacturer = ""; 
        formError.defaulttemplate = "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.name == "") {
            hasError = true; 
            formError.name = "Product Family name is required";  
        }

        if(formData.category == "") {
            hasError = true; 
            formError.category = "Product Family category is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Product manufacturer is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("products/families"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Product Family": "Update Product Family";

    return (

        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: '12px'}}>
                    Please enter the information about the product family
                </DialogContentText>

                <TextField label="Name" type="text" margin="dense" fullWidth autoFocus error={formError.name != ""} 
                    helperText={formError.name} value={formData.name} onChange={e => formData.name = e.target.value}/>

                <TextField label="Category" type="text" margin="dense" fullWidth error={formError.category != ""}
                    helperText={formError.category} value={formData.category} onChange={e => formData.category = e.target.value} />

                <TextField label="Manufacturer" type="text" margin="dense" fullWidth error={formError.manufacturer != ""}
                    helperText={formError.manufacturer} value={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />

                <Autocomplete
                    options={defaultTemplates}
                    getOptionLabel={(option) => option.name}
                    value={defaultTemplates.find(it => it.name == formData.defaultTemplate)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        formData.defaultTemplate = newValue.name;
                    }}
                    renderInput={(params) => <TextField {...params} label="Default Template" type="text" margin="dense" fullWidth />}
                />
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>

            <Modal open={modalOpen} onClose={()=>setModalOpen(false)}>
                <Box sx={{ ...modalStyle, width: 450 }}>
                    <h2 style={{marginTop: '0px'}}>Error</h2>
                    <p>
                        The machine with the given manufacturer, product code and serial number already exists.
                    </p>
                </Box>
            </Modal>
        </Dialog>
    );
});
