import React from 'react'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export type AuditLogPopupProps = {
    isOpen?: boolean
    onClose?: () => void
    initialData?: any
}


export function AuditLogPopup(props: AuditLogPopupProps) {
    let isOpen = props.isOpen || false;
    let initialData = props.initialData || [];


    const handleClose = () => {
        if (props.onClose != null) {
            props.onClose()
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={"md"}>
            <DialogTitle>Activity Logs</DialogTitle>
            <DialogContent>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell align="right">User</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {initialData.map((log, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {new Date(log.creationDate).toDateString()}
                                    </TableCell>
                                    <TableCell>{log.changeText}</TableCell>
                                    <TableCell align="right">{log.creatorEmail}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )

} 