import React from "react";
import { useMsal } from "@azure/msal-react";
import Link from '@mui/material/Link';
import { loginRequest } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

export function LoginPage() {
    const { instance, accounts } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
        });
    };
    return (
        <div>
            <div className="h-100 d-flex align-items-center justify-content-center pt-5">
                <div className="">
                    <p>You are not logged into MosaiQ</p>
                    <button type="button" className="btn p-2 m-2 bg-secondary w-100 text-white shadow text-center" onClick={handleLogin} style={{ cursor: 'pointer' }} >
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

